#Projects > div {
    width: 90vw;
    flex-grow: 0;

    display: grid;
    grid-template-areas: 
        'one one two three'
        'four five five three';
    grid-auto-columns: 1fr;
    gap: 1.5rem;

    > :nth-child(1) {
        grid-area: one;
    }
    > :nth-child(2) {
        grid-area: two;
    }
    > :nth-child(3) {
        grid-area: three;
    }
    > :nth-child(4) {
        grid-area: four;
    }
    > :nth-child(5) {
        grid-area: five;
    }
}
@media screen and (max-width: 1400px) {
    #Projects > div  {
        grid-template-areas: 
            'one one'
            'two three'
            'four three'
            'five five';
    }
}
@media screen and (max-width: 800px) {
    #Projects > div  {
        grid-template-areas: 
            'one'
            'two'
            'three'
            'four'
            'five';
    }
}

.Project {
    min-height: 256px;
    flex-grow: 0;
    transition: transform 0.35s ease;

    perspective: 1600px;

    .Project-Content {
        position: relative;
        width: 100%;
        height: 100%;

        display: block;
        overflow: hidden;

        border-radius: 6px;
        background-color: var(--box-bg-color);

        text-align: left;
        text-shadow: 0 0 4px white;
        box-shadow: 0 0 10px 0px var(--header-color), 0 0 40px 10px black inset;

        transform-origin: center;
        transform-style: preserve-3d;
        backface-visibility: hidden;

        transition: transform 0.6s cubic-bezier(0.23, 1, 0.32, 1);

        &::after {
            content: '';
            z-index: 2;
            position: absolute;
            inset: 0;
            background: linear-gradient(#ffffff00 50%, #ffffffaa 100%);
        }
        
        > * {
            position: relative;
            z-index: 3;
        }
    }

    .Project-Name {
        margin: 0;
        padding: 10px 10px;

        background: linear-gradient(var(--box-bg-color) 0%, white 300%);

        font-size: 1.6rem;
        font-weight: bold;

        box-shadow: 0 5px 5px -5px black;

        transition: color 0.5s ease;
    }

    .Project-Tech {
        display: inline-block;
        width: max-content;
        margin: 5px 0 0 5px;
        padding: 0 10px 2px 10px;

        background: var(--box-bg-color);
        border-radius: 100px;

        font-size: 1.2rem;
        font-weight: bold;
        line-height: 1;
    }
    
    .Project-Desc {
        margin: 0 10px;
        font-size: 1.4rem;

        position: absolute;
        left: 0;
        bottom: 10px;
    
        text-shadow: 1px 1px 0 #AAA, 0 0 4px white, 0 0 8px white;
    }
    
    .Project-BG {
        position: absolute;
        z-index: 1;
    
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;

        object-fit: cover;
    
        opacity: 0.8;

        --scale: 1.2;
        transform: scale(var(--scale));

        transition: transform 0.6s cubic-bezier(0.23, 1, 0.32, 1);
    }
    
    .Project-Link {
        position: absolute;
        right: 10px;
        bottom: 0px;
    
        color: black;
        text-decoration: none;
        font-size: 2rem;
    
        /*transform-origin: bottom right;*/
        transition: color 0.5s ease, transform 0.5s ease;

        &:focus {
            outline: none;
        }
    }

    &:hover,
    &:focus-within {
        /*transform: translateY(-16px) rotateY(-6deg);*/
        transform: scale(1.05);
        z-index: 10;

        .Project-Name {
            color: var(--accent-color);
        }
        .Project-Link {
            color: var(--accent-color);
            transform: scale(1.5);
        }
    }
}
