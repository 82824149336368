:root {
    --header-color: #282c34bb;
    --box-bg-color: rgba(255, 233, 166, 0.733);

    --bg-color: #fff8e3;
    --bg-color2: #ffe9a6;

    --accent-color: #af6300;

    scroll-behavior: smooth;
    font-size: 16px;
    text-align: center;
}

@media screen and (max-width: 500px) {
    :root {
        font-size: 12px;
    }
}

/* BACKGROUND */
body {
    background-color: var(--bg-color);
    background: linear-gradient(45deg, var(--bg-color) 0%, var(--bg-color2) 100%);
}

.App {
    &::before,
    &::after {
        margin: 0;
        padding: 0;

        color: var(--bg-color2);
        font-size: 768px;
        font-weight: bold;
    }

    &::before {
        position: fixed;
        z-index: -100;
        top: calc(100vh - 900px);
        right: 340px;
    
        content: 'A';
    }

    &::after {
        position: fixed;
        z-index: -101;
        top: calc(100vh - 700px);
        right: -60px;
    
        content: 'S';
    }
}

/* HEADER */
.Header {
    position: fixed;
    height: 64px;
    width: 100%;
    top: 0;
    z-index: 100;

    animation: intro-opacity 2s normal;
}

@media screen and (max-width: 800px) {
    .Header {
        position: relative;
        background-color: var(--header-color);
        box-shadow: 0 0 10px 2px var(--header-color);
    }
}

.Navigation {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    
    align-items: center;
    justify-content: flex-end;

    a,
    button {
        color: white;
        text-transform: uppercase;
        font-size: 1.5rem;
        margin-right: 16px;
        padding: 0;
        border: none;
        background: none;

        text-shadow: 0 0 10px #555;

        cursor: pointer;
    }
}

/* GENERAL SECTIONS */
main {
    overflow-x: hidden;
}

section {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;

    > * {
        flex-grow: 1;
    }

    > h1 {
        margin-left: 1%;
        width: 100%;
        margin-right: 1%;
        border-bottom: 2px solid black;
    
        box-shadow: 0 10px 10px -10px black;
    
        font-size: 2rem;
        text-align: left;
        text-transform: uppercase;
        text-shadow: 0 0 4px white;
    }
}

/* ELEMENT "MODIFIERS" */
.Clickable {
    cursor: pointer;
}
.Intro-Rotate {
    animation: intro-rotate 1s normal;
}
.Hidden {
    visibility: hidden;
}

/* ANIMATION KEY FRAMES */
@keyframes intro-left { 
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(0);
    }
}

@keyframes intro-right {
    from {
        transform: translateX(-100%);
    }
    to {
        transform: translateX(0);
    }
}

@keyframes intro-opacity {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes intro-rotate {
    from {
        transform: rotateY(90deg) rotateZ(-4deg);
    }
    to {
        transform: rotateY(0deg) rotateZ(0deg);
    }
}
