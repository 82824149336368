#Links {
    a {
        color: black;
        margin-bottom: 16px;
    }
    
    a:focus {
        outline: none;
    }

    a svg {
        transition: transform 0.5s ease;
    }
    
    a:hover svg,
    a:focus svg {
        filter: drop-shadow(0 0 5px black);
        transform: scale(1.2);
    }
}
